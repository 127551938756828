export const keyForTransaltionsPrivacyPoliy = () => {
  return {
    title: `privacyPolicyPage.title`,
    keysTranslate: [
      {
        title: `privacyPolicyPage.introductionTitle`,
        content: `privacyPolicyPage.introductionContent`,
      },
      {
        title: `privacyPolicyPage.useOurServicesTitle`,
        content: `privacyPolicyPage.useOurServicesContent`,
      },
      {
        title: `privacyPolicyPage.personalInformationTitle`,
        content: `privacyPolicyPage.personalInformationContent`,
      },
      {
        title: `privacyPolicyPage.consentTitle`,
        content: `privacyPolicyPage.consentContent`,
      },
      {
        title: `privacyPolicyPage.removalPersonalInformationTitle`,
        content: `privacyPolicyPage.removalPersonalInformationContent`,
      },
      {
        title: `privacyPolicyPage.dataDisclosureTitle`,
        content: `privacyPolicyPage.dataDisclosureContent`,
      },
      {
        title: `privacyPolicyPage.keepingPersonalInformationSecureTitle`,
        content: `privacyPolicyPage.keepingPersonalInformationSecureContent`,
      },
      {
        title: `privacyPolicyPage.rigthsTitle`,
        content: `privacyPolicyPage.rigthsContent`,
      },
      {
        title: `privacyPolicyPage.internationalTransfersInformationTitle`,

        content: `privacyPolicyPage.internationalTransfersInformationContent`,
      },
      {
        title: `privacyPolicyPage.thirdPartyLinksTitle`,
        content: `privacyPolicyPage.thirdPartyLinksContent`,
      },
      {
        title: `privacyPolicyPage.policyModificationsTitle`,
        content: `privacyPolicyPage.policyModificationsContent`,
      },
      {
        title: `privacyPolicyPage.cookiesPolicyTitle`,
        content: `privacyPolicyPage.cookiesPolicyContent`,
      },
    ],
  };
};

export const keyForTranslationsTermsAndConditions = () => {
  return {
    title: `termsAndConditionsPage.title`,
    keys: [
      {
        title: `termsAndConditionsPage.ourServiceOurCommitmentTitle`,
        content: `termsAndConditionsPage.ourServiceOurCommitmentContent`,
      },
      {
        title: `termsAndConditionsPage.termsUseAndServiceTitle`,
        content: `termsAndConditionsPage.termsUseAndServiceContent`,
      },
      {
        title: `termsAndConditionsPage.purposeServiceTitle`,
        content: `termsAndConditionsPage.purposeServiceContent`,
      },
      {
        title: `termsAndConditionsPage.accessServiceTitle`,
        content: `termsAndConditionsPage.accessServiceContent`,
      },
      {
        title: `termsAndConditionsPage.accessPersonalAccountsAuthorisationTitle`,
        content: `termsAndConditionsPage.accessPersonalAccountsAuthorisationContent`,
      },
      {
        title: `termsAndConditionsPage.collaborativeProviderPackagesServicesnTitle`,
        content: `termsAndConditionsPage.collaborativeProviderPackagesServicesnContent`,
      },
      {
        title: `termsAndConditionsPage.websiteDataInformationChargesSecurityTitle`,
        content: `termsAndConditionsPage.websiteDataInformationChargesSecurityContent`,
      },
      {
        title: `termsAndConditionsPage.ourRightChangeTermsTitle`,
        content: `termsAndConditionsPage.ourRightChangeTermsContent`,
      },
      {
        title: `termsAndConditionsPage.warrantiesTitle`,
        content: `termsAndConditionsPage.warrantiesContent`,
      },
      {
        title: `termsAndConditionsPage.ourLiabilityTitle`,
        content: `termsAndConditionsPage.ourLiabilityContent`,
      },
      {
        title: `termsAndConditionsPage.indemnificationTitle`,
        content: `termsAndConditionsPage.indemnificationContent`,
      },
      {
        title: `termsAndConditionsPage.terminationSuspensionTitle`,
        content: `termsAndConditionsPage.terminationSuspensionContent`,
      },
      {
        title: `termsAndConditionsPage.linkingOurSiteTitle`,
        content: `termsAndConditionsPage.linkingOurSiteContent`,
      },
      {
        title: `termsAndConditionsPage.generalApplicableLawTitle`,
        content: `termsAndConditionsPage.generalApplicableLawContent`,
      },
      {
        title: `termsAndConditionsPage.questionsComplaintsTitle`,
        content: `termsAndConditionsPage.questionsComplaintsContent`,
      },
    ],
  };
};
